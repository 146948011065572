import React, { useState, useEffect } from 'react'
import logo from '../../images/logo-black.svg';
import decoration from '../../images/decoration.svg';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import PhoneInput from 'react-phone-number-input'
import Portal from '../../components/Portal';
import { Link } from "react-router-dom";

import 'react-phone-number-input/style.css'
import "./WorkRequest.css";

export default function WorkRequest() {

    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('+380');
    const [subject, setSubject] = useState('');
    const [text, setText] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [ServiceCategory, setServiceCategory] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [file, setFile] = useState({ name: 'Додати файл', file: null });
    const [modalSettings, setModalSetting] = useState({
        heading: '',
        message: '',
        isError: false,
    });

    const selectOptions: { label: string, id: number }[] = [
        {
            id: 9,
            label: 'Ремонтні роботи'
        }
    ]

    const setPhoneValue = (value: string) => {
        setPhone(value);
    }

    const handleOptionChange = (e: any) => {
        setServiceCategory(e.target.value);
    }

    const handleFileChange = (e: any) => setFile(e.target.files[0]);

    const handleSubmitAgreement = (e: any) => {
        // e.preventDefault();
        setIsSubmitted((prev) => !prev);
    }

    const validateEmail = (email: string) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    
    
    
    const handleSubmit = (event: any) => {
        event.preventDefault();
        console.log(phone.length);

        if (phone.length < 13 || phone.length > 15) {
            setModalSetting(
                {
                    heading: 'Невірний номер телефону',
                    message: 'Будь ласка, введіть коректний номер телефону',
                    isError: true,
                }
            );
            return;
        }

        // check if satrt date is after end date
        if (new Date(startDate) > new Date(endDate)) {
            setModalSetting(
                {
                    heading: 'Невірна дата',
                    message: 'Будь ласка, введіть коректну дату',
                    isError: true,
                }
            );
            return;
        }
        

        //set error if fields are empty
        if (fullName === '' || email === '' || phone === '' || subject === '' || text === '' || startDate === '' || endDate === '' || isSubmitted === false) {
            setModalSetting(
                {
                    heading: 'Поля не заповнені',
                    message: 'Будь ласка, заповніть всі поля',
                    isError: true,
                }
            );
            return;
        }
      



        //set error if email is not valid
        if (!validateEmail(email)) {
            setModalSetting({
                heading: 'Неправильний email',
                message: 'Будь ласка, введіть правильний email',
                isError: true,
            });
            return;
        }

        //send data to server
        const url = 'https://www.corezoid.com/api/1/json/public/1131053/729f6ecea7be7f70323c51534420cf2831328cb4';
        const preparedData = {
            "ContactName": fullName,
            "WorkPhone": phone,
            "Email": email,
            "Subject": subject,
            "Symptoms": text,
            "ServiceCategory": ServiceCategory,
            "SuStartWork": startDate,
            "SuEndWork": endDate,
            "formid": 'form384124201',
            "headers": {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            },
        }

        const myInit = {
            method: 'POST',
            mode: 'no-cors' as RequestMode,
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": "true",
                "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
                "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
                'Content-Type': 'application/json',

            },
            body: JSON.stringify(preparedData),
        };

        console.log(myInit)

        const myRequest = new Request(url, myInit);

        fetch(myRequest).then(function (response) {
            return response;
        }).then(function (response) {
            console.log(response);
            setModalSetting({
                heading: 'Дякуємо!',
                message: 'Ваш запит успішно відправлений',
                isError: false,
            });
        }).catch(function (e) {
            console.log(e);
        });

    }



    return (
        <div className="formPage"
            style={{
                backgroundImage: `url(${decoration})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: "-400px 250px",
                backgroundSize: 'cover',
            }}
        >
            {
                modalSettings.heading !== '' && modalSettings.message !== '' ?
                    <Portal modalSetting={modalSettings} setModalSetting={setModalSetting} />
                    : null
            }

            <div className="containerForm">
                <header className="formHeader">
                    <Link to="/">
                        <div className="logoForm">
                            <img
                                src={logo}
                                alt="logo"
                                style={{
                                    width: '200px',
                                }}
                            />
                        </div>
                    </Link>
                    <div className="headingForm">
                        <h1>Запит на
                            роботи</h1>
                    </div>
                    <div className="actionForm">
                        <p> Будь ласка, заповніть усі необхідні дані для створення запиту. </p>
                    </div>
                </header>
                <div className="form">
                    <Form>

                        <Form.Group controlId="formBasicFullName" className='mb-3'>
                            <Form.Label>Як до вас звертатись?</Form.Label>
                            <Form.Control type="text" placeholder="Остап Вишня" value={fullName} onChange={e => setFullName(e.target.value)} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Ваш телефон</Form.Label>

                            <div className='phone-number'>
                                <PhoneInput
                                    placeholder="Введіть номер телефону"
                                    country="US"
                                    value={phone}
                                    onChange={setPhoneValue} />
                            </div>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Ваша електронна пошта</Form.Label>
                            <Form.Control type="email" placeholder="example@site.com" value={email} onChange={e => setEmail(e.target.value)} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Тема запиту</Form.Label>
                            <Form.Control type="text" placeholder="Наприклад: 'заміна лампи біля входу'" value={subject} onChange={e => setSubject(e.target.value)} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Опис запиту</Form.Label>
                            <Form.Control as="textarea" placeholder="Опишіть Ваше звернення детальніше" value={text} onChange={e => setText(e.target.value)} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Категорія сервісу</Form.Label>
                            <Form.Select aria-label="Default select example">
                                {selectOptions.map((option) => (
                                    <option key={option.id} value={option.id} onChange={handleOptionChange}>{option.label}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Дата початку робіт</Form.Label>
                            <Form.Control type="date" lang="uk-UA" placeholder="Введіть дату" value={startDate} onChange={e => setStartDate(e.target.value)} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Дата завершення робіт</Form.Label>
                            <Form.Control type="date" lang="uk-UA" placeholder="Введіть дату" value={endDate} onChange={e => setEndDate(e.target.value)} />
                        </Form.Group>

                        {/* file input */}


                        <div className="mb-3 checkbox-agree">

                            <Form.Check aria-label="option 1"
                                type="checkbox"
                                id="agree"
                                onChange={handleSubmitAgreement}
                            />
                            <Form.Check.Label>Я ознайомлений і &nbsp;
                                <a href="https://drive.google.com/file/d/1jiTd_KIFwgYKxQC3IkcaqdYNNLJq8ciq/view" target="_blank" rel="noopener noreferrer" style={{
                                    color: '#007bff',
                                    textDecoration: 'none',
                                    cursor: 'pointer',

                                }}>
                                    погоджуюся з правилами проведення робіт.
                                </a>
                            </Form.Check.Label>
                        </div>
                        {/* <div style={{ padding: "0 10px" }}>
                            <p >
                            Після ознайомлення завантажте список працівників для виконання робіт та заповніть його. Прикріпіть заповнений файл у поле нижче.
                                <a href="https://drive.google.com/file/d/1jiTd_KIFwgYKxQC3IkcaqdYNNLJq8ciq/view" target="_blank" rel="noopener noreferrer" style={{
                                    color: '#007bff',
                                    textDecoration: 'none',
                                    cursor: 'pointer',

                                }}>
                                    погоджуюся з правилами проведення робіт.
                                </a>
                            </p>
                        </div> 
                        <Form.Group controlId="formFile" className="mb-3">

                            <input type="file" id="formFile" onChange={handleFileChange} style={{ display: 'none' }} />
                            <label htmlFor="formFile" className="formFileLabel">
                                Custom Upload
                            </label>
                        </Form.Group>
                        */}

                        <Button variant="primary" type="submit" style={{ border: 'none', marginTop: '20px' }} onClick={handleSubmit}>
                            Відправити
                        </Button>

                    </Form>
                </div>
            </div>
        </div>

    )
}
