import useGoogleSheets from "use-google-sheets";
import React, { useState, useEffect, memo } from "react";


const App = ({ props }) => {

  const { selectedStore, counters, setCounters, gRefetch, setGRefetch } = props;


  const { data, loading, error, refetch } = useGoogleSheets({
    apiKey: 'AIzaSyCCJSljHS5bub9m152R2hsBEKAjwfC_WSo',
    sheetId: window.location.origin === 'http://localhost:3000' ?
      '1aFdSdKXg7oBSDELQ8-XAsF-g4Db7c8slvGWRHLovStc' :
      '1TtpwTzbJTBGx_PQF6maz267ruTIJvLFiflEewZ0gWRY',
  });

  

  useEffect(() => {
    
    if (gRefetch) {
      refetch();
      console.log('refetching');
      console.log(data);
      setGRefetch(false);
    }
  }, [gRefetch]);



  const pad = (n) => n < 10 ? "0" + n : n;




  useEffect(() => {
    if (data.length && selectedStore) {
      let dayNow = new Date();
      let day = pad(dayNow.getDate());
      const previosDay = pad(dayNow.getDate() - 1);


      const storeData = []
      data[0]?.data.reverse().forEach((item) => {
        const recordsDate = new Date(item.Время);
        const recordsDay = pad(recordsDate.getDate());

        if (item.Магазин === selectedStore && day === recordsDay) {
          storeData.push(item);
        }
        if (item.Магазин === selectedStore && previosDay === recordsDay) {
          storeData.push(item);
        }
      }
      )
      setCounters(storeData);
    }
  }, [data]);



  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error!</div>;
  }

  return <div style={{ backgroundColor: '#fff', padding: "20px", borderRadius: "20px", marginTop: '20px', width: '80%' }}>
    <div style={{ textAlign: 'center' }}>
      <h2>Зареєстровані показники сьогодні</h2>

    </div>
    {counters.length > 0 && counters.map((item, i) => {
      const recordDateTime = new Date(item.Время)
      const recordDate = recordDateTime.getDate();

      const date = new Date();
      const today = date.getDate();

      if (recordDate === today) {
        
        console.log(item.Показатель)
        
        return <div key={i} style={{ marginBottom: "10px", borderBottom: "1px solid black", paddingTop: "10px" }}>
          <p> Сьогодні : {recordDateTime.getHours()}:{pad(recordDateTime.getMinutes())}</p>
          <p> Лічильник: {item.Счетчик} | Показання: {item.Показатель}</p>
        </div>
      }
      else {
        return <div key={i} style={{ marginBottom: "10px", borderBottom: "1px solid black", paddingTop: "10px" }}>
          <p> Учора: {recordDateTime.getHours()}:{pad(recordDateTime.getMinutes())}</p>
          <p> Лічильник: {item.Счетчик} | Показання: {item.Показатель}</p>
        </div>
      }



    })}

    {counters.length == 0 && <div style={{ textAlign: 'center' }}> <h3>Немає даних</h3></div>}

  </div>;
};

export default memo(App);