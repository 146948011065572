import React from 'react'
import Form from 'react-bootstrap/Form';

export default function StoreName(props:any) {
    
    const {companyName} = props.props;

    return (
        <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Назва</Form.Label>
            <Form.Control type="text" placeholder={companyName} disabled />
        </Form.Group>
    )
}
