import React from "react";
import { Link } from "react-router-dom";
import logo from "../../images/logo.svg";
import "./MainPage.css";

export const MainPage: React.FC = () => {

    const categoryes: { name: string, id: number, link: string }[] =
        [
            { name: 'Запит на погодження', id: 1, link: '/consentRequest' },
            { name: 'Запит на роботи', id: 2, link: '/workRequest' },
            { name: 'Інцидент', id: 3, link: '/incident' },
            { name: 'Скарга', id: 4, link: '/complaint' },
            { name: 'Інше', id: 5, link: '/other' }
        ]

    return (
        <div className="App">
            <div className="container">
                <header className="App-header">
                    <img src={logo} className="App-logo" alt="logo" />
                </header>
                <div className="action">
                    <h1 className="action__title">
                        Сформувати запит
                    </h1>
                    <p className="action__subtitle">
                        Будь ласка, оберіть категорію, що відповідає вашому запиту, щоб перейти до заповнення короткої форми.
                    </p>
                </div>
                <div>
                    <ul className="links">
                        {categoryes.map((category) => (
                            <li className="links__link" key={category.id}>
                                <Link to={category.link}>
                                    <a href={category.link}>{category.name}</a>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>

            </div>
        </div>
    );
}