import React from 'react'
import Form from 'react-bootstrap/Form';

export default function SelectTime(props : any) {
    const timeOptions = [
        { value: '', label: 'Оберіть час' },
        { value: '10:00-11:00', label: 'Відкриття' },
        { value: '17:00-18:00', label: 'Активна фаза' },
        { value: '20:00-22:00', label: 'Закриття' }
    ]
    

    const {time, setTime, isYesterday, setIsYesterday} = props.props;


  return (
    <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Оберіть час:</Form.Label>

                            {!isYesterday ?
                                <Form.Control
                                    as="select"
                                    value={time}
                                    onChange={e => {
                                        setTime(e.target.value);
                                    }}
                                >
                                    {timeOptions.map((option: any) => (
                                        <option key={option.value} value={option.value} >{option.value}</option>
                                    ))}
                                </Form.Control>
                                :
                                <Form.Control
                                    as="select"
                                    value={'20:00-22:00'}
                                    disabled
                                >
                                    <option key={'20:00-22:00'} value={'20:00-22:00'} >{'20:00-22:00'}</option>
                                </Form.Control>

                            }


                            <Form.Group className="mb-3 section-time" controlId="formBasicPassword">
                                <Form.Label>Заповнити показники за вчора? </Form.Label>
                                <Form.Control className='typeCheckbox' type='checkbox' checked={isYesterday} onChange={() => { setIsYesterday((el: boolean) => !el); setTime('20:00-22:00') }} />
                            </Form.Group>
                        </Form.Group>
  )
}
