import React from 'react';
import { Route, Routes } from 'react-router-dom';
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import { MainPage } from './pages/MainPage/MainPage';
import ConsentRequest from './pages/ConsentRequest/ConsentRequest';
import WorkRequest from './pages/WorkRequest/WorkRequest';
import Incident from './pages/Incident/Incident';
import Complaint from './pages/Complaint/Complaint';
import Other from './pages/Other/Other';
import Counters from './pages/Counters/Counters';
import Parcking from './pages/Parcking/Parcking';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/consentRequest" element={<ConsentRequest />} />
        <Route path="/workRequest" element={<WorkRequest />} />
        <Route path="/incident" element={<Incident />} />
        <Route path="/complaint" element={<Complaint />} />
        <Route path="/other" element={<Other />} />
        <Route path="/electricity" element={<Counters />} />
        <Route path="/:id" element={<Parcking />} />

      </Routes>
    </Router>
  );
}

export default App;
