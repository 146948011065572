import React from 'react'
import Form from 'react-bootstrap/Form';

export default function CounterInput(props : any) {

    const {counter, setCounter} = props.props;

    return (
        <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Введіть показники лічильника</Form.Label>
            <Form.Control type="number" placeholder="Введіть показники лічильника" value={counter} onChange={e => { setCounter(e.target.value) }} />
        </Form.Group>
    )
}
