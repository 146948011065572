import React from 'react'
import Form from 'react-bootstrap/Form';

export default function SelectCounter(props: any) {
    console.log(props);
    const {selectOptions, selectedCounter, setSelectedCounter} = props.props;

    const handleCounterOptionChange = (e: any) => {
        setSelectedCounter(e);
    }

    return (
        <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Лічильник</Form.Label>
            <Form.Control
                as="select"
                value={selectedCounter}
                onChange={e => {

                    handleCounterOptionChange(e.target.value);
                }}
            >
                {selectOptions?.length && selectOptions.map((option: any) => (
                    <option key={option?.meteringPointNumber} value={option?.meteringPointNumber} >{option.meteringPointNumber}</option>
                ))}
            </Form.Control>
        </Form.Group>

    )
}
