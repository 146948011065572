import React, { useState, useEffect } from 'react'
import logo from '../../images/logo-black.svg';
import decoration from '../../images/decoration.svg';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import PhoneInput from 'react-phone-number-input'
import Portal from '../../components/Portal';
import { Link, useParams, useSearchParams, useLocation } from "react-router-dom";
// import useGoogleSheets from './useGoogleSheet.js';
import 'react-phone-number-input/style.css'
import stores from './companies_full.json';
import loader from '../../images/Loader.gif';
import GoogleSheets from './GoogleSheets.js';
import SelectCounter from '../../components/SelectCounter';
import "./style.css";
import SelectTime from '../../components/SelectTime';
import CounterInput from './CounterInput';
import StoreName from '../../components/StoreName';


// import "./Counters.css";
interface IStore {
    storeName: string;
    companyCode: any;
    metringPoints: any;
}


export default function Counters() {



    const data: { soreName: string, storeId: string }[] = [
        {
            soreName: 'Магазин 1',
            storeId: '1',
        }]
    const emptyCounter: { meteringPointNumber: string }[] = [
        {
            meteringPointNumber: '',
        }]
    const [selectOptions, setSelectOptions] = useState(emptyCounter);






    const [modalSettings, setModalSetting] = useState({
        heading: '',
        message: '',
        isError: false,
    });
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isButtonActive, setIsButtonActive] = useState(true);
    const search = useLocation().search;
    const [companyName, setCompanyName] = useState('');
    const [storeId, setStoreId] = useState('');
    const [counter, setCounter] = useState('');
    const [selectedStore, setSelectedStore] = useState('');
    const [selectedCounter, setSelectedCounter] = useState('');
    const [time, setTime] = useState('');
    const [isYesterday, setIsYesterday] = useState(false);
    const [gRefetch, setGRefetch] = useState(false);

    const handleSubmit = (event: any) => {
        event.preventDefault();
        setIsButtonActive(false);



        //send data to server
        const preparedData = {

            "companyName": companyName,
            "companyCode": storeId,
            "storeName": selectedStore,
            "meteringPointNumber": selectedCounter,
            "metringPointValue": counter,
            "time": isYesterday ? '20:00-22:00' : time,
            "isYesterday": isYesterday

            // "headers": {
            //     'Access-Control-Allow-Origin': '*',
            //     'Content-Type': 'application/json',
            // },
        }
        console.log(preparedData);
        const records = counters.filter((item: any) => item.Счетчик === selectedCounter);

        // find biggest value from records
        const biggestValue = Math.max(...records.map((item: any) => item.Показатель));

        if (Number(counter) < biggestValue) {
            setModalSetting({
                heading: 'Помилка',
                message: 'Показник не може бути меншим за попередній',
                isError: true,
            });
            setIsButtonActive(true);
            return;
        } else {



            const url = window.location.origin === 'http://localhost:3000' ?
                'http://localhost:4000/submit' :
                'https://hello-be2.respublikapark.com.ua/submit';

            if (url) {
                const myInit = {
                    method: 'POST',
                    // mode: 'no-cors' as RequestMode,
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Credentials": "true",
                        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
                        "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
                        'Content-Type': 'application/json',
                        "test": "test"
                    },
                    body: JSON.stringify(preparedData),
                };

                const myRequest = new Request(url, myInit);

                fetch(myRequest).then(function (response) {
                    return response;
                }).then(async function (response: any) {

                    // get body from response
                    const body = await response.json();


                    if (body.statusCode === 200) {

                        setModalSetting({
                            heading: 'Успіх',
                            message: 'Дані успішно відправлені',
                            isError: true,
                        });
                        setIsButtonActive(true);
                        setCounter('');
                        setTime('');
                        setSelectedCounter('');
                        setGRefetch(true);
                    } else if (body.statusCode === 400) {
                        setModalSetting
                            ({
                                heading: 'Помилка',
                                message: body.body,
                                isError: true
                            });

                    } else {
                        setModalSetting({
                            heading: 'Дякуємо!',
                            message: 'Ваш запит успішно відправлений',
                            isError: true,
                        });
                        setIsButtonActive(true);
                        setCounter('');
                        setTime('');
                        setSelectedCounter('');
                    }

                    setIsButtonActive(true);

                }).catch(function (e) {

                    setModalSetting({
                        heading: 'Помилка',
                        message: e.message,
                        isError: true,
                    });
                    setIsButtonActive(true);
                });
            }
        }
    }



    useEffect(() => {

        const storeID = search.split('=')[1];

        const store: any = stores.find((store: any) => store.companyCode === storeID);
        if (store) {
            setStoreId(store.companyCode);
            setCompanyName(store.storeName);

        } else {
            setModalSetting({
                heading: 'Помилка',
                message: 'Перевірте посилання, або зверніться до адміністратора',
                isError: false,
            });
        }

    }, [search]);

    useEffect(() => {
        // set options for counters

        const store = stores.find((store: any) => store.companyCode === storeId);

        if (store) {
            const empty = { meteringPointNumber: 'Оберіть лічильник' };
            const counterOptions = store.meteringPoints.map((counter: any) => {
                return { meteringPointNumber: counter.meteringPointNumber }
            }
            );
            const data: { meteringPointNumber: string }[] = [empty, ...counterOptions];
            setSelectedStore(store.storeName as string);
            setSelectOptions(data);
        }


    }, [storeId]);

    // update window width
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const [counters, setCounters] = useState([]);



    return (
        <div className="formPage"
            style={{
                backgroundImage: `url(${decoration})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: "-400px 250px",
                backgroundSize: 'cover',
            }}
        >
            {
                modalSettings.heading !== '' && modalSettings.message !== '' ?
                    <Portal modalSetting={modalSettings} setModalSetting={setModalSetting} />
                    : null
            }

            <div className="containerForm" style={{ minHeight: "100vh" }}>
                <header className="formHeader">
                    <Link to="/">
                        <div className="logoForm">
                            <img
                                src={logo}
                                alt="logo"
                                style={{
                                    width: '200px',
                                }}
                            />
                        </div>
                    </Link>
                    <div className="headingForm">
                        <h1>Реєстрація показників лічильників</h1>
                    </div>
                    <div className="actionForm">
                        <p> Будь ласка, заповніть усі необхідні дані для внесення показників лічильників. </p>
                        <p style={{ marginTop: "20px" }}> В разі виникнення питань, звертайтеся до адміністратора. </p>
                        <p style={{ marginTop: "10px" }}> Email: <a href="mailto:Karina.Lomakina@tio2.com.ua">
                            Karina.Lomakina@tio2.com.ua </a> <br />
                            Telegram: <a target="_blank" href="https://t.me/techSupportRespublika"> @techSupportRespublika </a> <br />
                            Телефон: <a href="tel:+380678710201">
                                +38 (067) 871-02-01 </a> </p>

                    </div>
                    {windowWidth > 1000 &&
                        <GoogleSheets props={{ gRefetch, setGRefetch, selectedStore, setCounters, counters }} />
                    }

                </header>
                <div className="form">
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <h1 style={{ width: '85%', marginBottom: '20px', textAlign: "center", fontSize: "20px" }}>Показники лічильників вносятся три рази на добу</h1>
                    </div>
                    <Form>
                        <StoreName props={{ companyName }} />
                        <SelectTime props={{ isYesterday, time, setIsYesterday, setTime }} />
                        <SelectCounter props={{ selectOptions, selectedCounter, setSelectedCounter }} />
                        <CounterInput props={{ counter, setCounter }} />


                        {isButtonActive ?
                            <Button variant="primary" type="submit" onClick={handleSubmit} style={{ cursor: 'pointer' }}>
                                Відправити
                            </Button>
                            :
                            <Button variant="primary" type="submit" disabled style={{ backgroundColor: '#e67cca', borderColor: '#e67cca' }}>
                                Йде відправка форми
                            </Button>
                        }


                    </Form>
                </div>
                {windowWidth <= 1000 &&
                    <GoogleSheets props={{ gRefetch, setGRefetch, selectedStore, counters, setCounters }} />
                }
            </div>
        </div>

    )
}
