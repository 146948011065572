import { useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import './Portal.css';

const modalRoot = document.getElementById('modal-root');

const Portal = (props: any) => {
    const el = useMemo(() => document.createElement('div'), []);
    const { modalSetting, setModalSetting } = props;
    console.log(modalSetting);
    console.log(setModalSetting);
    console.log(props);
   

    useEffect(() : any => {
        modalRoot?.appendChild(el);
        return () => modalRoot?.removeChild(el);
    }, [el]);


    return (

        <div className="modal">
            
            <div className="modal__content">
                <div className="modal__header">
                    <h3 className="modal__title">{modalSetting.heading}</h3>
                    <div className="modal__body">{modalSetting.message}</div>


                   {
                          modalSetting.isError ?
                          (<button className="modal__button" onClick={() => {                            
                                   setModalSetting({
                                       heading: '',
                                       message: '',
                                       isError: false,
                                   })
                               }}>OK</button>) : 
                                 (<Link to="/" style={{width: '80%'}} className="modal__button">
                                   <button > OK </button>
                                    </Link>) 

                           

                   }



                </div>
            </div>
        </div>
    );
}

export default Portal;