import React, { useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import bg from '../../images/bg-parcking.svg'
import Logo from '../../images/logo-black.svg'
import p7 from '../../images/p7.svg'
import './parcking.css'


type Props = {}

interface DynamicImportOptions {
  onCompleted?: (name: string, ImportedIconRef: any) => void;
  onError?: (err: Error) => void;
}

const importIcon = (name: string, options?: DynamicImportOptions) => {
  return import(`../../images/${name.toLocaleLowerCase()}.svg`).then((module) => {
    if (options?.onCompleted) {
      options.onCompleted(name, module.default);
    }
    return module.default;
  }).catch((err) => {
    if (options?.onError) {
      options.onError(err);
    }
    throw err;
  });
};


const Parcking = (props: Props) => {

  // use dynamic import for images
  const [ImportedIconRef, setImportedIconRef] = useState<any>(null);

  const [searchParams] = useSearchParams()
  const data = searchParams.get('parking')
  const [windowHeight, setWindowHeight] = React.useState(window.innerHeight)
  const [parckingLvl, setParckingLvl] = React.useState('')


  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    importIcon(parckingLvl, {
      onCompleted: (name, ImportedIconRef) => {
        setImportedIconRef(ImportedIconRef);
      },
      onError: (err) => {
        console.error(err);
      },
    });
  }, [parckingLvl]);

  useEffect(() => {
    // look url
    const url = window.location.href
    // get p1 or p2 or p3 or p4 or p5 or p6 or p7
    const parking = url.split('/').pop()
    // get p lvl from url
    const parkingLvl = parking?.split('?')[0]
    // set lvl
    setParckingLvl(parkingLvl as string)

  }, [])




  // container for all the data

  // using tailwind css
  return (
    <div className="flex justify-center  bg-no-repeat bg-cover" style={{
      backgroundImage: `url(${bg})`,
      minHeight: '100vh',
    }}>
      <div className="flex flex-col  mt-40 max-w-xl max-w-500 min-w-300 parckingContainer">
        <header className='flex items-start justify-start'>
          <img src={Logo} alt="logo" className='w-80' />
        </header>
        <div className="flex flex-col items-center justify-center mt-20">
          <img src={ImportedIconRef} alt="logo" className='w-full' />
        </div>
        <div className="flex flex-col items-center justify-center mt-10">
          <h3 className="text-2xl text-white font-medium text-center">Ваше авто знаходиться тут</h3>
        </div>
        <div className="flex flex-col items-center justify-center mt-10">
          <h3 className="text-9xl text-white font-medium text-center">{data}</h3>
        </div>
        <div className="flex flex-col items-center justify-center mt-10">
          <p className="text-white text-center">Щоб нагадати собі, де залишили авто:
            збережіть цю сторінку у закладках, або зробіть знімок екрану.</p>
        </div>
        <a href="https://respublikapark.com.ua/" target="_blank" rel="noreferrer">
          <button className="flex items-center justify-center mt-40 mb-40 rounded-xl" style={{
            backgroundColor: 'transparent',
            border: '1px solid #2cd5c4',
            color: '#2cd5c4',
          }}>
            <h3 className="text-2xlfont-medium text-center">На сайт ТРЦ Respublika Park</h3>
          </button>
        </a>


      </div>
    </div>
  )
}

export default Parcking